import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../constants/theme";
import { convertToArray } from "../utils/intlHelpers";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { ContentPage } from "../components/pageShared";

/**
 * ContactPage
 */
const ContactPage = () => {
  const intl = useIntl();
  const _pageId = "contact";
  const _title = intl.formatMessage({
    id: `content.contentPages.${_pageId}.title`,
  });
  const _content = convertToArray(
    intl,
    `content.contentPages.${_pageId}.content`
  );

  return (
    <Layout>
      <SEO title={_title} />
      <ContentPage title={_title} content={_content} />
    </Layout>
  );
};

export default ContactPage;
